<template>
  <div class="design-task-mode text-center cursor-pointer-custom" ref="items_body">

    <v-flex v-if="!getActiveFile.loading && loading"
            class="file-loading absolute inset-0 d-flex justify-center align-center">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </v-flex>
    <Portal to="comment-portal-target">
      <div :style="{ transform: transformStyle }" class="pointer-events-auto">
        <template v-for="(item, idx) in items">
          <VueDraggableResizable
            :key="new Date(item.created_at).getTime()"
            w="auto"
            h="auto"
            :minWidth="32"
            :minHeight="32"
            :x="position(item).x"
            :y="position(item).y"
            v-if="item.active"
            :draggable="!item.loading && editEnable(item)"
            :resizable="false"
            :parent="false"
            :style="{'z-index': item.showPreview ? getMaxItemIndex + 1 : item.parameters.z_index}"
            dragHandle=".design-task-marker"
            :class-name="`d-flex align-center justify-center absolute text-left ${item.showForm ? 'is-active' : ''} ${item.intersect ? '': 'hide'}`"
            @dragging="onDrag(item)"
            @dragstop="(x, y) => onDragStop(x, y, idx)"
            v-intersect="{
                  handler: (entries) => onIntersect(entries, item),
                  options: {
                    root: root,
                    rootMargin: '0px',
                    threshold: 1.0,
                  }
                }"
          >
            <v-hover v-model="item.showPreview">
              <div>
                <UiBtn
                  fab
                  width="auto"
                  height="auto"
                  min-width="32"
                  min-height="32"
                  color="accent"
                  @click="showItemForm(item)"
                  :disabled="item.loading"
                  class="design-task-marker cursor-pointer-custom"
                  :ref="`design_task_marker_${idx}`"
                >
                  {{ item.parameters.number }}
                </UiBtn>

                <CommentForm
                  v-if="selectedItem && selectedItem.id === item.id && selectedItem.type === 'comment' && item.showForm || item.showPreview && !item.dragging && item.type === 'comment'"
                  :comment="item"
                  :class="getMenuPosition(item)"
                  @createComment="saveComment($event, false)"
                  @updateComment="saveComment($event, true)"
                  @toggleCommentReadStatus="toggleCommentReadStatus"
                  @deleteComment="deleteComment"
                  @showItemForm="showItemForm(item)"
                  @closeItemForm="closeItemForm"
                  v-click-outside="{
                  handler: () => closeOutsideItem(items[idx]),
                  closeConditional: closeConditionalMethod,
                  include:  () => includeCloseElement(idx)
                }"
                  @click.stop
                />
                <TaskForm
                  v-if="selectedItem && selectedItem.id === item.id && selectedItem.type === 'task' && item.showForm || item.showPreview && !item.dragging && item.type === 'task'"
                  :task="item"
                  :class="getMenuPosition(item)"
                  @createTask="createTask"
                  @updateTask="updateTask"
                  @deleteTask="deleteTask"
                  @showItemForm="showItemForm(item)"
                  @closeItemForm="closeItemForm"
                  @wheel="onWheel"
                  v-click-outside="{
                  handler: () => closeOutsideItem(items[idx]),
                  closeConditional: closeConditionalMethod,
                  include: () => includeCloseElement(idx)
                }"
                  @click.stop
                />
              </div>
            </v-hover>
          </VueDraggableResizable>
        </template>


      </div>
    </Portal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {format} from "date-fns";
import VueDraggableResizable from 'vue-draggable-resizable';
import CommentForm from "@/views/project/design-view/modes/CommentForm";
import TaskForm from "./TaskForm";
import UiBtn from "@/components/UI/UiBtn";
import {createDataTree, delay} from "@/utils/helpers";
import {BASE_HEIGHT, BASE_WIDTH, VIEWPORT_HEIGHT, VIEWPORT_WIDTH} from "@/config";
import {Portal} from 'portal-vue'

export default {
  name: 'TaskMode',
  props: {
    zoom: {
      type: Number,
      default: 1
    },
    minZoom: {
      type: Number,
      default: 1
    },
    transform: Object
  },
  components: {
    Portal,
    VueDraggableResizable,
    CommentForm,
    TaskForm,
    UiBtn,
  },
  data() {
    return {
      loading: true,
      comments: [],
      tasks: [],
      selectedItem: null,
      root: null
    }
  },
  computed: {
    transformStyle() {
      return `translate(${this.transform.x || 0}px,${this.transform.y || 0}px)`
    },
    position() {
      return item => {
        const w = BASE_WIDTH * this.zoom;
        const h = BASE_HEIGHT * this.zoom;

        const px = item.parameters.x * 100 / BASE_WIDTH
        const py = item.parameters.y * 100 / BASE_HEIGHT


        const newX = Math.round((w * px) / 100)
        const newY = Math.round((h * py) / 100)

        return {
          x: newX,
          y: newY
        }
      }
    },
    ...mapGetters([
      'getAuthId',
      'getUser',
      'getProject',
      'getActiveFile',
      'getActiveStyle',
      'getPermission',
      'getProjectSideDrawerTasks',
      'getProjectPermissionData',
      'getActiveDesignMode',
    ]),
    items() {
      return [...this.comments, ...this.tasks].sort((a, b) => a.parameters.number < b.parameters.number ? 1 : -1);
    },
    getLastItemNumber() {
      const arr = this.items.map(task => task.parameters.number);
      return arr.length ? Math.max(...arr) : 0;
    },
    getMaxItemIndex() {
      const arr = this.items.map(task => task.parameters.z_index);
      return arr.length ? Math.max(...arr) : 0;
    },
    getMenuPosition() {
      return item => {
        const position = [];
        position[0] = item.parameters.y < BASE_HEIGHT / 2 ? 'top' : 'bottom';
        position[1] = item.parameters.x < BASE_WIDTH / 2 ? 'left' : 'right';

        return position;
      }
    },
    getItemModeRect() {
      const {width, height} = this.$refs.items_body.getBoundingClientRect()
      return {
        width,
        height
      }
    }
  },
  watch: {
    '$route.query.file_id': {
      async handler(newVal) {
        if (newVal) {
          await this.refetchData()
        }
      }
    },
    '$route.query.task_id': {
      handler(nv) {
        this.openPreloadedCommentOrTask(nv)
      }
    },
    '$route.query.comment_id': {
      handler(nv) {
        this.openPreloadedCommentOrTask(nv)
      }
    }
  },
  async mounted() {
    this.root = document.querySelector('.file-view')
    await this.fileLoaded();
    this.$eventBus.$on('updateSideDrawerProjectTask', data => {
      const {actionType, task} = data;
      if (actionType === 'delete') {
        this.tasks = this.tasks.filter(localTask => localTask.id !== task.id);
      }
      if (actionType === 'update') {
        this.tasks = this.tasks.map(localTask => localTask.id === task.id ? this.formatTaskFromBackend(task) : localTask);
      }
    });
    this.fitBounds();
  },
  beforeDestroy() {
    this.$eventBus.$off('updateSideDrawerProjectTask');
  },
  methods: {
    includeCloseElement(idx) {
      const include = [
        ...document.querySelectorAll('.thread-comment-menu'),
        ...document.querySelectorAll('.v-overlay'),
        ...document.querySelectorAll('.v-dialog'),
        ...document.querySelectorAll('.v-menu__content'),
        this.$refs[`design_task_marker_${idx}`]?.[0]?.$el
      ]
      return include.filter(Boolean)
    },
    closeConditionalMethod() {
      return this.getActiveDesignMode !== this.$config.project.designModes.task
    },
    closeOutsideItem(item) {
      item.showForm = false;
      item.showPreview = false;

      if (this.selectedItem) {
        this.selectedItem.showForm = false;
      }
      this.selectedItem = null;
    },
    editEnable(item) {
      const {projectOwner, projectManager} = this.$config.project.userRole;

      if (this.getActiveFile.loading || this.loading) return false;

      if (item.id === null) {
        return this.getPermission(this.$route.params.project_id).design['can-create-task-design'];
      }

      return item.created_by.id === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    },
    findNodeById(tree, id) {
      for (const node of tree) {
        if (node.id === id) {
          return node;
        }

        if (node.children && node.children.length > 0) {
          const foundInChildren = this.findNodeById(node.children, id);
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }

      return null;
    },
    async fileLoaded() {
      try {
        const [comments, tasks] = await Promise.all([this.loadComments(), this.loadTasks()]);
        this.comments = createDataTree('id', 'parent_id', comments.data.map(comment => this.formatCommentFromBackend(comment)));
        this.tasks = tasks.data.map(task => this.formatTaskFromBackend(task));

        const viewCommentId = this.$route.query.comment_id || this.$route.query.task_id;

        if (viewCommentId) {
          this.openPreloadedCommentOrTask(viewCommentId)
        }

      } catch (error) {
        console.error(error);
      }

      this.loading = false;

    },
    async loadComments() {
      const {project_id} = this.$route.params;
      const {module_id} = this.$route.query;
      const file_id = this.getActiveFile.id
      return await this.$api.comment.list(project_id, module_id, {item_id: file_id});
    },
    async loadTasks() {
      const {project_id} = this.$route.params;
      const {module_id, style_id} = this.$route.query;
      const file_id = this.getActiveFile.id
      return await this.$api.task.list(`projects/${project_id}/tasks`, {
        project_module_id: module_id,
        project_style_id: style_id,
        project_tag_id: this.getActiveStyle.tags[0] ? this.getActiveStyle.tags[0].id : null,
        project_item_id: file_id,
        count: -1,
      });
    },
    addItem(event) {
      if (this.getActiveFile.loading || this.loading) return;

      const clearStuff = key => {
        this[key] = this[key].reduce((acc, item) => {
          if (item.id !== null) {
            acc.push({...item, showForm: false});
          }
          return acc;
        }, []);
      }

      clearStuff('comments');
      clearStuff('tasks');

      this.$nextTick(() => {
        const {module_id, style_id, file_id} = this.$route.query;
        const {
          x: newX,
          y: newY
        } = this.getPosition(event.absolutePointer.x * this.zoom, event.absolutePointer.y * this.zoom, this.getLastItemNumber + 1)
        const newItemTemplate = {
          id: null,
          type: 'comment', // comment|task
          name: '',
          description: '',
          priority: this.$config.tasks.priority.critical,
          status: this.$config.tasks.status.to_do,
          parent: {id: null},
          project_module_id: module_id,
          project_style_id: style_id,
          project_tag_id: this.getActiveStyle.tags[0] ? this.getActiveStyle.tags[0].id : null,
          project_item_id: file_id,
          created_at: new Date(),
          due_date: new Date().setHours(0, 0, 0, 0),
          reminder: new Date().setHours(0, 0, 0, 0),
          created_by: this.getUser,
          assignee: this.getProject.team.find(user => user.user.id === this.getAuthId).user,
          attachments: [],
          parameters: {
            x: newX,
            y: newY,
            number: this.getLastItemNumber + 1,
            z_index: this.getMaxItemIndex + 1,
          },
          showForm: true,
          showPreview: false,
          loading: false,
          dragging: false,
          intersect: false,
          active: true,
          tagged_users: [],
        }

        this.selectedItem = newItemTemplate;
        this.comments.push(newItemTemplate);
      })
    },
    onDrag(item) {
      if (!item.dragging) {
        this.selectedItem = null;

        if (item.parameters.z_index !== this.getMaxItemIndex) {
          item.parameters.z_index = this.getMaxItemIndex + 1;
        }

        item.showForm = false;
        item.dragging = true;
        this.selectedItem = item;
      }
    },
    async onDragStop(x, y, idx) {
      const {x: newX, y: newY} = this.getPosition(x, y, idx)
      this.selectedItem.parameters.x = newX
      this.selectedItem.parameters.y = newY
      this.selectedItem.active = false
      await this.$nextTick()


      if (this.selectedItem.id === null) {
        this.updateItem(this.selectedItem);
      } else {
        this.selectedItem.dragging = false;
        this.selectedItem.showForm = true;
        if (this.selectedItem.type === 'comment') {
          await this.updateComment();
        } else {
          await this.updateTask();
        }
      }
      this.selectedItem.active = true
    },
    getPosition(x, y, idx) {
      const borderSpace = 8
      const itemWidth = (this.$refs[`design_task_marker_${idx}`]?.[0]?.$el?.clientWidth || 32)
      const itemHeight = (this.$refs[`design_task_marker_${idx}`]?.[0]?.$el?.clientHeight || 32)

      const maxLeft = (VIEWPORT_WIDTH - VIEWPORT_WIDTH / 2 - BASE_WIDTH / 2) * -1
      const maxRight = (VIEWPORT_WIDTH - VIEWPORT_WIDTH / 2 + BASE_WIDTH / 2)


      const maxTop = (VIEWPORT_HEIGHT - VIEWPORT_HEIGHT / 2 - BASE_HEIGHT / 2) * -1
      const maxBottom = (VIEWPORT_HEIGHT - VIEWPORT_HEIGHT / 2 + BASE_HEIGHT / 2)

      if (maxTop > (y + borderSpace) / this.zoom) {
        y = maxTop + borderSpace
      } else if (maxBottom < (y + itemHeight + borderSpace) / this.zoom) {
        y = maxBottom - itemHeight / this.minZoom - borderSpace
      } else {
        y = y / this.zoom
      }

      if (maxLeft > (x + borderSpace) / this.zoom) {
        x = maxLeft + borderSpace
      } else if (maxRight < (x + itemWidth + borderSpace) / this.zoom) {
        x = (maxRight - itemWidth / this.minZoom) - borderSpace
      } else {
        x = x / this.zoom
      }
      return {x, y}
    },
    showItemForm(item) {
      if (item.parameters.z_index !== this.getMaxItemIndex) {
        item.parameters.z_index = this.getMaxItemIndex + 1;
      }

      this.items.forEach(i => {
        if (i.id !== item.id) {
          i.showForm = false;
          i.showPreview = false;
        }
      })

      item.dragging = false;
      item.showForm = !item.showForm;
      item.showPreview = false;
      this.selectedItem = item;
      this.updateItem(this.selectedItem);

    },
    closeItemForm() {
      if (this.selectedItem.id === null) {
        this.comments.pop();
        this.selectedItem = null;
        return;
      }
      this.selectedItem.showForm = false;
      this.selectedItem.showPreview = false;
      this.updateItem(this.selectedItem);
    },
    updateItem(item) {
      const key = this.selectedItem.type === 'comment' ? 'comments' : 'tasks';
      this[key] = this[key].map(keyItem => keyItem.id === item.id ? item : keyItem);
    },
    async saveComment(comment, isUpdate = false) {
      if (!comment.parent.id) {
        comment.loading = true;
        this.updateItem(comment);
      }
      if (comment.attachments.find(file => file.file)) {
        await this.createItemAttachmentFiles(comment);
      }
      try {
        const res = isUpdate ? await this.$api.comment.update(
          this.$route.params.project_id, this.$route.query.module_id, comment.id, this.formatCommentToBackend(comment)
        ) : await this.$api.comment.create(this.$route.params.project_id, this.$route.query.module_id, this.formatCommentToBackend(comment));

        const formattedData = this.formatCommentFromBackend(res.data)
        if (comment.parent.id) {
          this.comments = this.comments.map(item => item.id === comment.parent.id ?
            {
              ...item,
              replyLoading: false,
              children: isUpdate ? item.children.map(item => item.id === res.data.id ? formattedData : item) : [...item.children, formattedData]
            } : item);
        } else {
          if (isUpdate) {
            this.comments = this.comments.map(item => item.id === comment.id ? {
              ...formattedData,
              children: item.children || [],
              showForm: item.showForm,
              showPreview: item.showPreview,
              loading: false,
              replyLoading: false,
              intersect: item.intersect,
              active: item.active
            } : item);
          } else {
            this.comments = this.comments.map(item => item.id === comment.id ? formattedData : item);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateComment(comment) {
      if (!comment) {
        comment = this.selectedItem;
      }

      comment.loading = true;
      this.updateItem(comment);

      try {
        await this.$api.comment.update(
          this.$route.params.project_id, this.$route.query.module_id, comment.id, this.formatCommentToBackend(comment)
        );

        comment.loading = false;
        this.updateItem(comment);
      } catch (error) {
        console.error(error);
      }
    },
    async toggleCommentReadStatus(comment) {
      try {
        const res = await this.$api.comment.toggleReadStatus(this.$route.params.project_id, this.$route.query.module_id, comment.id);

        this.comments = this.comments.map(item => {
          if (item.id === comment.id) {
            return {...item, readed_at: res.data.readed_at}
          }
          if (item.id === comment.parent_id) {
            return {
              ...item,
              children: item.children.map(reply => reply.id === comment.id ? {
                ...reply,
                readed_at: res.data.readed_at
              } : reply),
            }
          }
          return item;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async deleteComment(deleteComment) {
      if (deleteComment.created_by.id !== this.getAuthId) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to <br> delete ${!deleteComment.parent_id ? 'entire thread' : 'this comment'}?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            this.comments = this.comments.reduce((acc, comment) => {
              if (comment.id === deleteComment.parent_id) {
                acc.push({
                  ...comment,
                  children: comment.children.filter(reply => reply.id !== deleteComment.id),
                });
              } else if (comment.id !== deleteComment.id) {
                acc.push(comment)
              }

              return acc;
            }, []);

            if (!deleteComment.parent_id) {
              this.selectedItem = null;
            }

            this.$store.dispatch('closeModal', 'confirmModal');
            await this.$api.comment.delete(this.$route.params.project_id, this.$route.query.module_id, deleteComment.id);
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
    async createTask(task) {
      task.loading = true;
      this.updateItem(task);

      if (task.attachments.find(file => file.file)) {
        await this.createItemAttachmentFiles(task);
      }

      try {
        const res = await this.$api.task.create(this.$route.params.project_id, {
          ...task,
          created_by: task.created_by.id,
          assignee_id: task.assignee.id,
          due_date: format(task.due_date, 'yyyy-MM-dd'),
          reminder: new Date(task.reminder),
          attachments: task.attachments.map(file => file.id),
          tagged_users: task.tagged_users.map(user => user.id),
          parent_task_id: task.parent?.id || null,
          parameters: {
            ...task.parameters,
            'z-index': task.parameters.z_index,
          },
        });

        this.comments = this.comments.filter(item => item.id !== task.id);
        this.tasks.push(this.formatTaskFromBackend(res.data));

        this.$toast.open({
          message: 'Task created successfully',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateTask(task = null) {
      if (task === null) {
        task = this.selectedItem;
      }

      task.loading = true;
      this.updateItem(task);

      if (task.attachments.find(file => file.file)) {
        await this.createItemAttachmentFiles(task);
      }

      try {
        const res = await this.$api.task.update(this.$route.params.project_id, task.id, {
          ...task,
          assignee_id: task.assignee.id,
          due_date: format(task.due_date, 'yyyy-MM-dd'),
          reminder: new Date(task.reminder),
          attachments: task.attachments.map(file => file.id),
          tagged_users: task.tagged_users.map(user => user.id),
          parent_task_id: task.parent?.id || null,
          parameters: {
            ...task.parameters,
            'z-index': task.parameters.z_index,
          },
        });

        task.loading = false;
        this.updateItem(task);

        if (this.getProjectSideDrawerTasks.data.length) {
          this.$store.dispatch('setProjectSideDrawerTabData', {
            tab: 'tasks',
            data: this.getProjectSideDrawerTasks.data.map(sdTask => {
              if (this.task.parent && this.task.parent.id && sdTask.id) {
                return {
                  ...sdTask,
                  children: sdTask.children.map(subTask => subTask.id === this.task.id ? res.data : subTask),
                }
              } else if (sdTask.id === res.data.id) {
                return res.data
              } else {
                return sdTask
              }
            }),
          });
        }
      } catch (error) {
        console.error(error)
      }
    },
    deleteTask() {
      if (!this.editEnable(this.selectedItem)) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete <br> “${this.selectedItem.name}“?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            const taskId = this.selectedItem.id;
            this.tasks = this.tasks.filter(task => task.id !== this.selectedItem.id);
            this.selectedItem = null;

            this.$store.dispatch('closeModal', 'confirmModal');
            await this.$api.task.delete(this.$route.params.project_id, taskId);

            if (this.getProjectSideDrawerTasks.data.length) {
              await this.$store.dispatch('setProjectSideDrawerTabData', {
                tab: 'tasks',
                data: this.getProjectSideDrawerTasks.data.filter(sdTask => sdTask.id !== taskId),
              });
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
    async createItemAttachmentFiles(item) {
      let formData = new FormData();
      const files = item.attachments.filter(file => file.file);

      files.forEach(file => {
        formData.append(`file[]`, file.file);
      });

      const attachments = await this.$api.task.uploadAttachments(this.$route.params.project_id, formData);

      item.attachments = [
        ...item.attachments.filter(file => !file.file),
        ...attachments.data,
      ];
    },
    async openPreloadedCommentOrTask(comment_id, ignore_search = false) {
      if (comment_id) {
        const commentById = this.findNodeById(this.items, Number(comment_id))
        const commentOrCommentParent = commentById?.parent_id ? this.findNodeById(this.items, commentById.parent_id) : commentById
        if (commentOrCommentParent) {
          this.showItemForm(commentOrCommentParent)
          this.$emit('viewComment', commentOrCommentParent.parameters)
        } else {
          if (!ignore_search) {
            await this.refetchData()
            await delay(0)
            await this.openPreloadedCommentOrTask(comment_id, true)
          }
        }
        await this.$router.$updateQueryParams({task_id: null, comment_id: null});
      }
    },
    formatTaskFromBackend(task) {
      return Object.assign({}, task, {
        type: 'task',
        due_date: new Date(task.due_date).getTime(),
        reminder: new Date(task.reminder).getTime(),
        parameters: {
          ...task.parameters,
          z_index: task.parameters['z-index'],
        },
        parent: {id: task.parent ? task.parent.id : null},
        showForm: false,
        showPreview: false,
        loading: false,
        intersect: false,
        active: true
      });
    },
    formatCommentToBackend(comment) {
      return {
        created_by: comment.created_by.id,
        description: comment.description,
        readed_at: comment.readed_at || null,
        item_id: this.$route.query.file_id,
        parent_id: comment.parent ? comment.parent.id : null,
        attachments: comment.attachments.map(item => item.id),
        parameters: comment.parameters || null,
        tagged_users: comment.tagged_users.map(user => user.id),
      };
    },
    async onIntersect(entries, item) {
      if (!entries[0].isIntersecting) {
        await this.$nextTick()

        item.intersect = false
        item.showForm = entries[0].isIntersecting
      } else {
        item.intersect = true
      }
    },
    formatCommentFromBackend(comment) {
      return Object.assign({}, comment, {
        type: 'comment',
        created_by: comment.creator,
        children: [],
        showForm: false,
        showPreview: false,
        loading: false,
        replyLoading: false,
        intersect: true,
        active: true
      })
    },
    onWheel(e) {
      this.$emit('wheel', {x: e.deltaX, y: e.deltaY})
    },
    fitBounds() {
      if (this.$route.query.task_id || this.$route.query.comment_id) return

      const commentsCoordinate = this.comments.map(el => ({
        left: this.position(el).x - 1,
        top: this.position(el).y - 2,
        width: 32 + 2 + 32 / this.minZoom,
        height: 32 + 32 / this.minZoom + 4
      }))
      const tasksCoordinate = this.tasks.map(el => ({
        left: this.position(el).x - 1,
        top: this.position(el).y - 2,
        width: 32 + 2 + 32 / this.minZoom,
        height: 32 + 32 / this.minZoom + 4
      }))
      this.$eventBus.$emit('fitBounds', [...commentsCoordinate, ...tasksCoordinate])
    },
    async refetchData() {
      this.comments = [];
      this.tasks = [];
      this.selectedItem = null;
      this.loading = true;

      await this.fileLoaded();
      await delay(0)
      this.fitBounds();
    }
  }
}
</script>

<style lang="scss">
.task-mode {
  position: absolute;
  z-index: 3;

  .file-loading {
    background-color: rgb(255, 255, 255, .4);
  }

  .vdr {
    position: absolute;
    box-sizing: border-box;
    touch-action: none;
  }

  .handle {
    display: none !important;
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
</style>
